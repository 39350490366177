import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";

import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";

import "../../scss/pages/404.scss";


class Error404 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }


  render() {
    return (
      <Layout>
        <Helmet title={config.siteTitle} />
        <SEO {...this.props} />

        <section className='content'>
          <header>
            <h1 className="text-center hover-color">Oops!</h1>
            <h2 className="text-center">404 Not Found</h2>
            <article className="contact row justify-content-center">
              <p className="col-12 text-center">Sorry, an error has occured, Requested page not found!</p>
              <Link
                to="/"
                className="btn btn-dark btn-lg"
              >
                Zurück
              </Link>
            </article>
          </header>

        </section>
      </Layout>
    );
  }
}

export default Error404;
